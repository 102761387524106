import { useState, useEffect } from "react";
import { Table, Space, Dropdown, PageHeader, Tag, Typography, Button } from "antd";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { CustomerCell, MoneyCell, OrdersActionsMenu } from "../components/tablecells";
import { StatusTag } from "../components/tags/StatusTag";
import moment from "moment";
import { ResourceToolbar } from "../components/ResourceToolbar";
import { DollarCircleOutlined, EditOutlined } from "@ant-design/icons";
import { PageContainer } from "../components/atoms/PageContainer";
import { OrdersAsInvoicesQuery } from "../graphql/orders.graphql";
import { getTodayTomorrowTag } from "../utils";
import { OrderStatusTypes } from "../constants/appConstants";

const columns = [
  {
    title: "#",
    dataIndex: "name",
    key: "name",
    render: (orderNumber, record) => {
      return (
        <Space>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            <Link to={record.isCreditNote ? `/credit-notes/${record.id}` : `/orders/${record.id}`}>
              {orderNumber}
            </Link>
          </Typography.Title>
          {record.isCreditNote && <Tag color="gold">CN</Tag>}
        </Space>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
    render: (orderDate) => {
      const { dateStr, tag } = getTodayTomorrowTag(orderDate);
      return (
        <Space>
          {dateStr} {tag && <Tag color={tag.color}>{tag.text}</Tag>}
        </Space>
      );
    },
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    render: (_, record) => (
      <CustomerCell
        isCreditNote={record.isCreditNote}
        orderId={record.id}
        customer={record.customer || {}}
      />
    ),
  },
  {
    title: "Payment Status",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (text) => <StatusTag status={text} />,
  },
  {
    title: "Paid",
    dataIndex: "balancePaid",
    key: "balancePaid",
    ...MoneyCell,
  },
  {
    title: "Outstanding",
    dataIndex: "balanceDue",
    key: "balanceDue",
    ...MoneyCell,
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    ...MoneyCell,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "right",
    render: (_, record) => {
      return (
        <Space>
          <Dropdown.Button trigger={["click"]} overlay={<OrdersActionsMenu order={record} />}>
            <Link
              to={
                record.isCreditNote
                  ? `/credit-notes/${record.id}/edit`
                  : `/orders/${record.id}/edit`
              }
            >
              Edit <EditOutlined />
            </Link>
          </Dropdown.Button>
        </Space>
      );
    },
  },
];

const pageSize = 50;
export const Invoices = () => {
  const [filters, setFilters] = useState({
    status: { equals: OrderStatusTypes.CONFIRMED },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicesCount, setInvoicesCount] = useState(0);
  const { data, loading, refetch, networkStatus } = useQuery(OrdersAsInvoicesQuery, {
    variables: {
      where: filters,
      take: pageSize,
      skip: (currentPage - 1) * pageSize,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (data?.orders) {
      setInvoices(data.orders);
      setInvoicesCount(data.ordersCount);
    }
  }, [data, loading]);

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  const handleSearch = (value) => {
    const namefilter = { name: { contains: value, mode: "insensitive" } };
    setFilters((f) => ({ ...f, OR: [{ customer: namefilter }, namefilter] }));
    setCurrentPage(1);
  };

  const handleRangeChange = (range) => {
    if (!range) {
      const fil = { ...filters };
      delete fil.orderDate;
      setFilters(fil);
      return;
    }

    const [start, end] = range;

    if (!start) {
      setFilters((f) => ({
        ...f,
        orderDate: {
          lte: end.endOf("day").toISOString(),
        },
      }));
      return;
    }
    if (!end) {
      setFilters((f) => ({
        ...f,
        orderDate: {
          gte: start.startOf("day").toISOString(),
        },
      }));
      return;
    }

    setFilters((f) => ({
      ...f,
      orderDate: {
        gte: start.startOf("day").toISOString(),
        lte: end.endOf("day").toISOString(),
      },
    }));
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title={moment().format("YYYY-MM-DD, dddd")}
        subTitle={`Showing ${invoices.length} of ${invoicesCount} invoices (confirmed orders and credit notes)`}
        extra={[
          <Link key="new-credit-note" to={"/credit-notes/new"}>
            <Button icon={<DollarCircleOutlined />}>New Credit Note</Button>
          </Link>,
        ]}
      />

      <PageContentContainer>
        <ResourceToolbar
          search={{ onSearch: handleSearch }}
          dateRange={{
            onRangeChange: handleRangeChange,
            range: [null, null],
          }}
          onRefresh={handleRefresh}
        />

        <Table
          dataSource={invoices}
          columns={columns}
          rowKey="id"
          size="medium"
          loading={loading || networkStatus}
          pagination={{
            total: invoicesCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
