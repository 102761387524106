import { useAuth } from "../hooks/useAuth";
import { Form, Input, Button, Card, notification, Row, Col } from "antd";
import { LogoWithText } from "../layouts/dashboard/header/LogoWithText";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import styled from "styled-components";

const SplashScreenWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 16px;
  height: 100%;
  min-height: 100vh;
  width: 100%;
`;

const SplashImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  background: #dff8ed;
  align-self: stretch;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 24px;
`;

export const Login = () => {
  const { login, authenticated } = useAuth();
  const from = useLocation().state?.from || "/";
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate(from, { replace: true });
    }
  }, [authenticated, navigate, from]);

  const handleLoginSubmit = async (values) => {
    const { username, password } = values;

    const res = await login(username, password);
    const result = res.data.authenticateUserWithPassword;
    if (result["__typename"] === "UserAuthenticationWithPasswordFailure") {
      notification.error({
        message: "Sorry, username/password is not corret.",
      });
    } else {
      notification.success({ message: "Successfully logged in." });
      navigate(from, { replace: true });
    }
  };
  return (
    <SplashScreenWrapper>
      <SplashImageWrapper>
        <img width={600} height={600} src="/images/login-splash.svg" alt="splash" />
      </SplashImageWrapper>
      <LoginFormWrapper>
        <LogoWithText />
        <Card bordered={false} style={{ width: "100%" }}>
          <p>Sign in to your account</p>
          <Form
            name="basic"
            onFinish={handleLoginSubmit}
            autoComplete="off"
            layout="vertical"
            size="large"
            stlye={{ width: "100%" }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </LoginFormWrapper>
    </SplashScreenWrapper>
  );
};
