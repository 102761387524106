import PropTypes from "prop-types";
import { AuthGuard } from "../guards/AuthGuard";
// guards
// import AuthGuard from "../guards/AuthGuard";
// components
import { DashboardLayout } from "./dashboard";

// ----------------------------------------------------------------------

export const DefaultLayout = ({ children }) => {
  return (
    <AuthGuard>
      <DashboardLayout>{children}</DashboardLayout>
    </AuthGuard>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
