import { Space } from "antd";

export const VendorInfo = ({ vendor }) => {
  return (
    <Space align="center">
      <img
        style={{
          height: "32px",
          width: "auto",
          objectFit: "cover",
        }}
        alt="logo"
        src={
          vendor === "GLORY_FRESH"
            ? `https://res.cloudinary.com/wohu-studio/image/upload/v1693812314/FreshCloud/glory_a5li5y.png`
            : `https://res.cloudinary.com/wohu-studio/image/upload/v1693812314/FreshCloud/newnorth_ehdunj.jpg`
        }
      />
      <h3>{vendor === "GLORY_FRESH" ? "Glory Fresh" : "Newnorth"}</h3>
    </Space>
  );
};
