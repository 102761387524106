import { CheckCircleFilled, LockFilled } from "@ant-design/icons";
import { Space, Tag } from "antd";
const statusIcon = {
  active: <CheckCircleFilled fill="green" />,
  suspended: <LockFilled />,
  archived: null,
};

const statusColor = {
  active: "green",
  suspended: "red",
  archived: "default",
};
export const CustomerStatusTag = ({ status }) => {
  return (
    <Tag color={statusColor[status]}>
      {statusIcon[status]} {status?.toUpperCase()}
    </Tag>
  );
};
