import { Card, DatePicker, Form, Input, Select, Space } from "antd";
import { useContext, useState } from "react";
import { OrderFormContext } from "../../contexts/OrderFormContext";
import { DateTag } from "../../components/tags/DateTag";
import moment from "moment-timezone";
import { getInitialOrderDate } from "../../utils";

export const BasicInfo = () => {
  const { order } = useContext(OrderFormContext);
  const [date, setDate] = useState(
    order?.orderDate ? moment(order?.orderDate) : getInitialOrderDate()
  );

  const handleDateChange = (date) => {
    setDate(date);
  };

  return (
    <Card title="Basic">
      <Form.Item
        name={["basicInfo", "orderDate"]}
        label={
          <Space>
            Date <DateTag date={date} />
          </Space>
        }
      >
        <DatePicker onChange={handleDateChange} />
      </Form.Item>
      <Form.Item name={["basicInfo", "fulfillmentType"]} label="Fulfillment type">
        <Select
          options={[
            { label: "Delivery", value: "delivery" },
            { label: "Pick up", value: "pickup" },
          ]}
        />
      </Form.Item>
      <Form.Item label="Delivery address" name={["basicInfo", "deliveryAddress"]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Delivery notes" name={["basicInfo", "deliveryNotes"]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Packer Notes" name={["basicInfo", "packerNotes"]}>
        <Input.TextArea />
      </Form.Item>
    </Card>
  );
};
