import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { notification, PageHeader, Button, Form } from "antd";

import { PageContainer } from "../components/atoms/PageContainer";
import { CustomerForm } from "../components/CustomerForm";
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import { prepareCustomerFormValues } from "../utils";
import { useAuth } from "@/hooks/useAuth";

const CreateCustomerMutation = gql`
  mutation CreateCustomerMutation($data: CustomerCreateInput!) {
    createCustomer(data: $data) {
      id
      name
    }
  }
`;

export const NewCustomer = () => {
  const [createCustomer] = useMutation(CreateCustomerMutation);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);
  const { user } = useAuth();

  // based on showPrompt and isSaved Status to show or not show Modal
  useEffect(() => {
    // setShowModal(true);
    if (isSaved) {
      setShowModal(false);
    } else {
      setShowModal(showPrompt);
    }
  }, [showPrompt, isSaved]);

  const handleSave = async () => {
    setIsSaved(true);
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const operation = "new";
      const preparedValues = prepareCustomerFormValues(values, operation, user);

      const res = await createCustomer({ variables: { data: preparedValues } });

      if (res.data.createCustomer.id) {
        notification.success({
          message: `${res.data.createCustomer.name} successfully created.`,
        });
        navigate("/customers");
        confirmNavigation();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <PageContainer>
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <PageHeader
        ghost={true}
        title="New customer"
        onBack={() => navigate("/customers")}
        extra={[
          <Button type="primary" key="save" onClick={handleSave}>
            Save
          </Button>,
        ]}
      />

      <CustomerForm form={form} />
    </PageContainer>
  );
};
