import PropTypes from "prop-types";
import { useAuth } from "../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import { FullScreenWrapper } from "../components/atoms/FullScreenWrapper";
import { Spin } from "antd";

export const AuthGuard = ({ children }) => {
  const { authenticated, loading } = useAuth();
  const location = useLocation();

  if (!loading) {
    if (authenticated) {
      return children;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace={true} />;
    }
  } else {
    return (
      <FullScreenWrapper>
        <Spin />
      </FullScreenWrapper>
    );
  }
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
