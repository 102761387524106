import { createContext, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { ProductsBaseQuery } from "../graphql/products.graphql";

const intialContextValues = {
  variants: [],
  loading: undefined,
  error: undefined,
  refetch: () => null,
};

const ProductsContext = createContext(intialContextValues);

const ProductsProvider = ({ children }) => {
  const { data, loading, error, refetch } = useQuery(ProductsBaseQuery, {
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions
  });

  const [productVariantsOptions, setProductsVariantsOptions] = useState([]);

  useEffect(() => {
    if (data && data.products) {
      console.log("prepared products for quick search", data.products.length);
      const options = data.products.reduce(
        (prev, current) => {
          return {
            productVariants: [
              ...prev.productVariants,
              ...current.productVariants.map((variant) => ({
                label: `${current.name} / ${variant.name}`,
                value: `${current.name} / ${variant.name}`,
                variant: { ...variant, product: { id: current.id, name: current.name } },
              })),
            ],
          };
        },
        {
          products: [],
          productVariants: [{ label: "+ Create an Open Item", value: "", variant: null }],
        }
      );
      setProductsVariantsOptions(options.productVariants);
    }
  }, [data]);

  return (
    <ProductsContext.Provider
      value={{
        variants: productVariantsOptions,
        loading: loading,
        error: error,
        refetch: refetch,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export { ProductsContext, ProductsProvider };
