import React, { useState, useEffect } from "react";
import { Button, notification } from "antd";
import { CopyFilled, PrinterFilled } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { usePDF } from "@react-pdf/renderer";

import { OrderMissingItemsQuery } from "../../graphql/orders.graphql";
import { useLazyQuery } from "@apollo/client";

import { MissingItemsByTruckPDF } from "../pdf/MissingItemsByTruckPDF";

import { RunsheetPDF } from "../../components/pdf/RunsheetPDF";
import { RunsheetStatementPDF } from "../pdf/RunsheetStatementPDF";
import moment from "moment-timezone";

export const PrintByTruck = ({ trucks, orders, truckName, date, totalCountOfOrders }) => {
  const [eachTruckOrderIds, setEachTruckOrderIds] = useState([]);
  const [getOrderMissingItems, { data: missingItemsData }] = useLazyQuery(OrderMissingItemsQuery, {
    fetchPolicy: "network-only",
  });
  const [printResult, setPrintResult] = useState([]);
  const [ready, setReady] = useState(false);

  const [missingItemsByTruckPDF, updateMissingItemsByTruckPDF] = usePDF({
    document: <MissingItemsByTruckPDF items={printResult} truck={trucks[truckName]} date={date} />,
  });

  const [runsheetPDFInstance, updateRunsheetPDFInstance] = usePDF({
    document: (
      <RunsheetPDF
        trucks={[trucks[truckName]]}
        allOrders={orders}
        date={date}
        totalCountOfOrders={totalCountOfOrders}
      />
    ),
  });

  const [runsheetStatementPDFInstance, updateRunsheetStatementPDF] = usePDF({
    document: (
      <RunsheetStatementPDF
        trucks={[trucks[truckName]]}
        allOrders={orders}
        date={date}
        totalCountOfOrders={totalCountOfOrders}
      />
    ),
  });

  useEffect(() => {
    setEachTruckOrderIds(trucks[truckName].orderIds);
    // eslint-disable-next-line
  }, [trucks]);

  useEffect(() => {
    if (missingItemsData && missingItemsData?.orders.length > 0) {
      setPrintResult(missingItemsData?.orders);
    }
  }, [missingItemsData]);

  useEffect(() => {
    if (printResult.length > 0) {
      updateMissingItemsByTruckPDF();
      setReady(true);
    }
    // eslint-disable-next-line
  }, [printResult]);

  useEffect(() => {
    if (
      ready &&
      missingItemsByTruckPDF &&
      !missingItemsByTruckPDF.loading &&
      !missingItemsByTruckPDF.error
    ) {
      window.open(missingItemsByTruckPDF.url, "_blank")?.focus();
      setReady(false);
    }
  }, [ready, missingItemsByTruckPDF]);

  useEffect(() => {
    if (Object.keys(orders).length) {
      updateRunsheetPDFInstance();
    }
    // eslint-disable-next-line
  }, [trucks, orders]);

  useEffect(() => {
    if (Object.keys(orders).length) {
      updateRunsheetPDFInstance();
      updateRunsheetStatementPDF();
    }
    // eslint-disable-next-line
  }, [trucks, orders]);

  const printMissingItems = async () => {
    await getOrderMissingItems({ variables: { ids: eachTruckOrderIds } });
  };

  const handleCopyTruckUrl = () => {
    const content = `${window?.location.hostname}/delivery-runs/${moment().format(
      "YYYY-MM-DD"
    )}/trucks/${truckName}`;

    navigator.clipboard
      .writeText(content)
      .then(() => {
        notification.success({ message: "Copied" });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const menu = (
    <Menu
      key={truckName}
      items={[
        {
          key: "Runsheet",
          label: (
            <Button
              type="text"
              icon={<PrinterFilled />}
              target="_blank"
              href={runsheetPDFInstance.url}
              loading={runsheetPDFInstance.loading}
              disabled={runsheetPDFInstance.loading}
            >
              Runsheet
            </Button>
          ),
        },
        {
          key: "Missing_items",
          label: (
            <Button
              type="text"
              icon={<PrinterFilled />}
              onClick={printMissingItems}
              loading={missingItemsByTruckPDF.loading}
              // disabled={missingItemsByTruckPDF.loading}
              disabled={missingItemsByTruckPDF.loading || missingItemsByTruckPDF.error}
            >
              Missing items
            </Button>
          ),
        },
        {
          key: "runsheet_statement",
          label: (
            <Button
              type="text"
              icon={<PrinterFilled />}
              target="_blank"
              href={runsheetStatementPDFInstance.url}
              loading={runsheetStatementPDFInstance.loading}
              disabled={runsheetStatementPDFInstance.loading}
            >
              Statement
            </Button>
          ),
        },
        {
          key: "copy",
          label: (
            <Button type="text" icon={<CopyFilled />} onClick={handleCopyTruckUrl}>
              Copy link
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} disabled={!eachTruckOrderIds.length}>
      <Button type="text" size="small" icon={<PrinterFilled />} />
    </Dropdown>
  );
};
