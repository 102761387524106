import { gql } from "@apollo/client";

export const OrdersQuery = gql`
  query OrdersQuery($take: Int, $skip: Int, $where: OrderWhereInput) {
    orders(take: $take, skip: $skip, orderBy: { name: desc }, where: $where) {
      id
      name
      sequence
      status
      total
      totalBasePrice
      lineItemsCount
      fulfillmentStatus
      fulfillmentType
      orderDate
      createdAt
      updatedAt
      pickingSlipPrintedAt
      organisation {
        id
        name
      }
      customer {
        id
        name
        salesperson {
          id
          name
        }
      }
    }
    ordersCount(where: $where)
  }
`;

export const OrdersByIdsQuery = gql`
  query OrdersByIdsQuery($ids: [ID!]!) {
    orders(where: { id: { in: $ids }, status: { not: { equals: "cancelled" } } }) {
      id
      name
      deliveryAddress
      notes
      status
      customer {
        name
      }
    }
  }
`;

export const OrdersSummaryReportQuery = gql`
  query OrdersSummaryReportQuery($where: OrderWhereInput) {
    orders(orderBy: { createdAt: desc }, where: $where) {
      id
      name
      status
      total
      lineItemsCount
      orderDate
      customer {
        id
        name
      }
    }
  }
`;

export const OrdersForAllocation = gql`
  query OrdersForAllocation($take: Int, $skip: Int, $where: OrderWhereInput) {
    orders(take: $take, skip: $skip, orderBy: { orderDate: desc }, where: $where) {
      id
      name
      sequence
      status
      total
      lineItemsCount
      lineItems {
        id
        name
        quantity
        packagingUnit
        unitPrice
        productVariant {
          id
          name
          name_EN
          isDefaultUnit
          convertToDefaultUnit
        }
        product {
          id
          name
          name_EN
          productType
          defaultUnit
          alternativeUnit
          displayUnit
          displayQuantity
          inventoryQuantity
          alternativeUnit
          unitConversionRate
        }
      }
      fulfillmentStatus
      orderDate
      createdAt
      updatedAt
      customer {
        id
        name
      }
    }
  }
`;

export const OrdersAsInvoicesQuery = gql`
  query OrdersAsInvoicesQuery($take: Int, $skip: Int, $where: OrderWhereInput) {
    orders(take: $take, skip: $skip, orderBy: { orderDate: desc }, where: $where) {
      id
      name
      sequence
      status
      total
      balanceDue
      balancePaid
      paymentStatus
      isCreditNote
      orderDate
      createdAt
      updatedAt
      customer {
        id
        name
      }
    }
    ordersCount(where: $where)
  }
`;

export const OrdersNextAndPrev = gql`
  query OrdersNextAndPrev($id: ID!, $orderDate: DateTime) {
    nextOrders: orders(
      orderBy: { orderDate: asc }
      take: 1
      where: { customer: { id: { equals: $id } }, orderDate: { gt: $orderDate } }
    ) {
      id
      name
    }
    prevOrders: orders(
      orderBy: { orderDate: desc }
      take: 1
      where: { customer: { id: { equals: $id } }, orderDate: { lt: $orderDate } }
    ) {
      id
      name
    }
  }
`;

export const SingleOrderQuery = gql`
  query SingleOrderQuery($id: ID!) {
    order(where: { id: $id }) {
      id
      name
      sequence
      status
      total
      totalWithoutGst
      gstAmount
      discountPercentage
      discountAmount
      gstPercentage
      totalBasePrice
      grossProfit
      paymentMethod
      paymentTerms
      paymentStatus
      fulfillmentStatus
      invoiceStatus
      notes
      deliveryNotes
      history
      packerNotes
      deliveryBatch
      deliveryAddress
      fulfillmentType
      orderDate
      balanceDue
      balancePaid
      payments {
        amount
        name
      }
      lineItems(orderBy: { sortingIndex: asc }) {
        id
        name
        quantity
        unitPrice
        notes
        sortingIndex
        packagingUnit
        pickingStatus
        isOpenItem
        product {
          id
          name
          name_EN
          name_ZH
          productVariants {
            name
            name_EN
            name_ZH
            id
          }
        }
        productVariant {
          id
          name
          name_EN
          name_ZH
          fullName
          fullName_EN
          fullName_ZH
          defaultMargin
          isDefaultUnit
          basePrice
          sellingPrice
        }
      }
      createdAt
      updatedAt
      confirmedAt
      customer {
        id
        name
        email
        phone
        status
        billingAddress
        accountBalance
        fixedMargin
        invoiceTemplate
        franchise {
          id
          name
        }
        deliveryAddress
        notes
      }
    }
  }
`;

export const SingleOrderAsCreditNoteQuery = gql`
  query SingleOrderAsCreditNoteQuery($id: ID!) {
    order(where: { id: $id }) {
      id
      name
      sequence
      status
      status
      total
      totalWithoutGst
      gstAmount
      discountPercentage
      discountAmount
      gstPercentage
      totalBasePrice
      grossProfit
      paymentMethod
      paymentTerms
      paymentStatus
      fulfillmentStatus
      invoiceStatus
      notes
      packerNotes
      deliveryBatch
      deliveryAddress
      fulfillmentType
      orderDate
      balanceDue
      balancePaid
      payments {
        amount
        name
      }
      lineItems(orderBy: { sortingIndex: asc }) {
        id
        name
        quantity
        unitPrice
        sortingIndex
        isOpenItem
        lineTotal
      }
      createdAt
      updatedAt
      confirmedAt
      customer {
        id
        name
        invoiceTemplate
        email
        phone
        billingAddress
        accountBalance
        fixedMargin
        franchise {
          id
          name
        }
        deliveryAddress
        notes
      }
    }
  }
`;

export const SoftDeleteOrderMutation = gql`
  mutation SoftDeleteOrderMutation($id: ID!) {
    updateOrder(where: { id: $id }, data: { status: "cancelled", isCancelled: true }) {
      id
      status
    }
  }
`;

export const UpdateOrderAndLineItemsMutation = gql`
  mutation UpdateOrderAndLineItemsMutation(
    $id: ID!
    $data: OrderUpdateInput!
    $deletedIds: [LineItemWhereUniqueInput!]!
    $lineItemsUpdateData: [LineItemUpdateArgs!]!
  ) {
    updateOrder(where: { id: $id }, data: $data) {
      id
    }

    deleteLineItems(where: $deletedIds) {
      id
      name
    }

    updateLineItems(data: $lineItemsUpdateData) {
      id
      name
    }
  }
`;

export const UpdateOrderMutation = gql`
  mutation UpdateOrderMutation($id: ID!, $data: OrderUpdateInput!) {
    updateOrder(where: { id: $id }, data: $data) {
      id
      name
      sequence
      status
      total
      totalWithoutGst
      gstAmount
      gstPercentage
      paymentMethod
      paymentTerms
      paymentStatus
      fulfillmentStatus
      notes
      packerNotes
      deliveryAddress
      balanceDue
      balancePaid
      lineItems(orderBy: { sortingIndex: asc }) {
        id
        name
        quantity
        unitPrice
        sortingIndex
        packagingUnit
        pickingStatus
        product {
          id
          name
          name_EN
          productType
          productVariants {
            id
            name
            defaultMargin
            basePrice
          }
        }
        productVariant {
          id
          name
          defaultMargin
          basePrice
        }
      }
      createdAt
      customer {
        id
        name
        email
        phone
        billingAddress
        fixedMargin
      }
      packer {
        name
        packerId
      }
    }
  }
`;

export const deleteLineItemsMutation = gql`
  mutation deleteLineItemsMutation($data: [LineItemWhereUniqueInput!]!) {
    deleteLineItems(where: $data) {
      id
      name
    }
  }
`;

export const updateLineItemsMutation = gql`
  mutation updateLineItemsMutation($data: [LineItemUpdateArgs!]!) {
    updateLineItems(data: $data) {
      id
      name
    }
  }
`;

export const CreateOrderMutation = gql`
  mutation create_order($order: OrderCreateInput!) {
    createOrder(data: $order) {
      id
      name
      customer {
        name
      }
    }
  }
`;

export const UpdateOrdersMutation = gql`
  mutation UpdateOrdersMutation($data: [OrderUpdateArgs!]!) {
    updateOrders(data: $data) {
      id
      name
      paymentStatus
      balancePaid
      balanceDue
      total
    }
  }
`;

export const UnpaidOrdersQuery = gql`
  query UnpaidOrdersQuery {
    orders(where: { balanceDue: { gt: "0" } }) {
      id
      name
      customer {
        id
        name
      }
    }
  }
`;

export const UpdateInvoiceStatusMutation = gql`
  mutation UpdateInvoiceStatusMutation($id: ID!, $invoiceStatus: String) {
    updateOrder(where: { id: $id }, data: { invoiceStatus: $invoiceStatus }) {
      invoiceStatus
      id
    }
  }
`;

export const OneDayOrdersQuery = gql`
  query OneDayOrdersQuery($start: DateTime, $end: DateTime) {
    orders(
      where: {
        orderDate: { lte: $end, gte: $start }
        isCancelled: { equals: false }
        isCreditNote: { equals: false }
      }
      orderBy: { name: asc }
    ) {
      id
      name
      fulfillmentStatus
      status
      customer {
        id
        name
        defaultRunName
      }
      orderDate
      total
    }
  }
`;

//missing items order in each truck
export const OrderMissingItemsQuery = gql`
  query OrderMissingItemsQuery($ids: [ID!]) {
    orders(where: { id: { in: $ids } }) {
      id
      name
      customer {
        name
      }
      lineItems(where: { pickingStatus: { equals: "missing" } }) {
        name
        quantity
        pickingStatus
        productVariant {
          name
        }
      }
    }
  }
`;

export const UpdateOrderPickingSlipPrintedAtMutation = gql`
  mutation UpdateOrderPickingSlipPrintedAtMutation($id: ID!, $pickingSlipPrintedAt: DateTime!) {
    updateOrder(where: { id: $id }, data: { pickingSlipPrintedAt: $pickingSlipPrintedAt }) {
      id
      name
      pickingSlipPrintedAt
      packerNotes
      customer {
        name
      }
      lineItems(orderBy: { sortingIndex: asc }) {
        id
        name
        quantity
        sortingIndex
        notes
        productVariant {
          name
          name_EN
          name_ZH
        }
        product {
          name
          name_EN
          name_ZH
        }
        isOpenItem
      }
    }
  }
`;

export const OrdersOfAFranchiseQuery = gql`
  query OrdersOfAFranchiseQuery($where: OrderWhereInput) {
    orders(orderBy: { name: desc }, where: $where) {
      id
      name
      sequence
      status
      total
      totalBasePrice
      lineItems {
        name
        product {
          id
          name
        }
        productVariant {
          id
          name
        }
        unitPrice
      }
      orderDate
      createdAt
      updatedAt
      customer {
        id
        name
      }
    }
    ordersCount(where: $where)
  }
`;
