import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";

export const CustomerCell = ({ customer, orderId, isCreditNote = false }) => {
  const { id, name } = customer;
  return id ? (
    <Space>
      <Typography.Title level={4} style={{ marginBottom: 0 }}>
        <Link to={isCreditNote ? `/credit-notes/${orderId}` : `/orders/${orderId}`}>{name}</Link>
      </Typography.Title>
      <Link to={`/customers/${id}`}>
        <LinkOutlined />
      </Link>
    </Space>
  ) : (
    ""
  );
};
