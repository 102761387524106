import { Row, Col, Card, Typography, Button, Space, Divider } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants";
import { FileAddOutlined, SnippetsOutlined, UserAddOutlined } from "@ant-design/icons";
import { useAuth } from "@/hooks/useAuth";

export const HomePage = () => {
  const { user } = useAuth();
  return (
    <>
      <Typography.Title level={4}>Welcome, {user?.name}</Typography.Title>
      <Typography.Text type="secondary">{user?.organisations[0]?.name}</Typography.Text>
      <Divider />
      <Row gutter={16}>
        <Col span={6}>
          <Card hoverable>
            <Link to={ROUTES.ordersNew}>
              <Space>
                <FileAddOutlined style={{ fontSize: 30 }} />
                <h3 style={{ marginBottom: 0 }}>New Order</h3>
              </Space>
            </Link>
          </Card>
        </Col>

        <Col span={6}>
          <Card hoverable>
            <Link to={ROUTES.orders}>
              <Space>
                <SnippetsOutlined style={{ fontSize: 30 }} />
                <h3 style={{ marginBottom: 0 }}>All orders</h3>
              </Space>
            </Link>
          </Card>
        </Col>

        <Col span={6}>
          <Card hoverable>
            <Link to={ROUTES.customersNew}>
              <Space>
                <UserAddOutlined style={{ fontSize: 30 }} />
                <h3 style={{ marginBottom: 0 }}>Add a customer</h3>
              </Space>
            </Link>
          </Card>
        </Col>
      </Row>
    </>
  );
};
