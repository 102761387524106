import { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  Space,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Divider,
  PageHeader,
  Menu,
  Dropdown,
} from "antd";
import { usePDF } from "@react-pdf/renderer";

import { useParams } from "react-router-dom";
import { OrdersNextAndPrev, SingleOrderQuery } from "../graphql/orders.graphql";

import { OrderInfoCard } from "../components/OrderInfoCard";
import { OrderTotalSummary } from "../components/OrderTotalSummary";
import { PageContainer } from "../components/atoms/PageContainer";
import { LineItemsTable } from "../components/LineItemsTable";
import { StatusTag } from "../components/tags/StatusTag";
import {
  EditFilled,
  PrinterOutlined,
  FilePdfOutlined,
  SyncOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { InvoicePDF } from "../components/pdf/InvoicePDF";
// import { SendEmailModal } from "../components/SendEmailModal";

const itemPaddingLeft = { paddingLeft: "18px" };

export const OrderDetail = () => {
  const params = useParams();
  const [orderIdList, setOrderIdList] = useState({ nextId: null, prevId: null });
  const [order, setOrder] = useState({});
  const [items, setItems] = useState([]);
  const [orderDate, setOrderDate] = useState("");
  const [customer, setCustomer] = useState({ name: "" });
  const { data, loading, refetch } = useQuery(SingleOrderQuery, {
    variables: { id: params.id },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "cache-and-network",
    // fetchPolicy: "network-only",
  });
  const [getOrdersInLine, { data: ordersInLine }] = useLazyQuery(OrdersNextAndPrev, {
    fetchPolicy: "cache-and-network",
  });

  const [invoiceMulti, updateInvoiceMulti] = usePDF({
    document: <InvoicePDF order={order} orderDate={orderDate} language="multi" />,
  });
  const [invoiceEnglish, updateInvoiceSingle] = usePDF({
    document: <InvoicePDF order={order} orderDate={orderDate} language="single" />,
  });

  useEffect(() => {
    if (data?.order) {
      setOrder(data.order);
      setItems(data.order.lineItems);
      setCustomer(data.order.customer);
      setOrderDate(moment(data.order.orderDate).format("DD/MM/YYYY"));
      getOrdersInLine({
        variables: { id: data.order.customer.id, orderDate: data.order.orderDate },
      });
    }
  }, [data, getOrdersInLine]);

  useEffect(() => {
    if (ordersInLine) {
      setOrderIdList({
        nextId: ordersInLine.nextOrders[0]?.id,
        prevId: ordersInLine.prevOrders[0]?.id,
      });
    }
  }, [ordersInLine]);

  useEffect(() => {
    if (order.id) {
      updateInvoiceSingle();
    }
    if (order.id) {
      updateInvoiceMulti();
    }
    // eslint-disable-next-line
  }, [order]);

  const printPDF = (
    <Menu
      items={[
        {
          key: "1",
          icon: <PrinterOutlined />,
          style: itemPaddingLeft,
          label: (
            <a target="_blank" rel="noreferrer" href={invoiceMulti.url}>
              ZH & EN
            </a>
          ),
        },
        {
          key: "2",
          icon: <PrinterOutlined />,
          style: itemPaddingLeft,
          label: (
            <a target="_blank" rel="noreferrer" href={invoiceEnglish.url}>
              English
            </a>
          ),
        },
      ]}
    />
  );

  const downloadPDF = (
    <Menu
      items={[
        {
          key: "3",
          icon: <FilePdfOutlined />,
          style: itemPaddingLeft,
          label: (
            <a
              target="_blank"
              rel="noreferrer"
              href={invoiceMulti.url}
              loading={invoiceMulti.loading}
              download={`${customer.name}_INV#${order.name}_${moment(order.orderDate).format(
                "DD/MM/YYYY"
              )}.pdf`}
            >
              ZH & EN
            </a>
          ),
        },
        {
          key: "4",
          icon: <FilePdfOutlined />,
          style: itemPaddingLeft,
          label: (
            <a
              target="_blank"
              rel="noreferrer"
              href={invoiceEnglish.url}
              loading={invoiceMulti.loading}
              download={`${customer.name}_INV#${order.name}_${moment(order.orderDate).format(
                "DD/MM/YYYY"
              )}.pdf`}
            >
              English
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <PageHeader
        title={
          <Space>
            <Typography.Text># {order.name}</Typography.Text>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {customer.name}
            </Typography.Title>
            <Space>
              <StatusTag status={order.status} />
              <StatusTag status={order.fulfillmentStatus} />
              <StatusTag status={order.paymentStatus} />
              <StatusTag status={order.invoiceStatus} />
            </Space>
          </Space>
        }
        extra={[
          <Link key="prev-order" to={`/orders/${orderIdList.prevId}`}>
            <Button
              type=""
              loading={loading}
              disabled={!orderIdList.prevId}
              icon={<ArrowLeftOutlined />}
            ></Button>
          </Link>,
          <Link key="next-order" to={`/orders/${orderIdList.nextId}`}>
            <Button
              type=""
              loading={loading}
              disabled={!orderIdList.nextId}
              icon={<ArrowRightOutlined />}
              iconPosition="right"
            ></Button>
          </Link>,
          <Link key="edit-order-v2" to={`/orders/v2/${order.id}/edit`}>
            <Button type="primary" loading={loading} icon={<EditFilled />}>
              Edit
            </Button>
          </Link>,
          <Dropdown key="download-order" overlay={downloadPDF} placement="bottom">
            <Button
              loading={invoiceMulti.loading || invoiceEnglish.loading}
              icon={<FilePdfOutlined />}
            >
              Download
            </Button>
          </Dropdown>,
          <Button
            key="refresh"
            onClick={() => refetch()}
            loading={loading}
            icon={<SyncOutlined />}
          />,
        ]}
      />
      <PageContainer>
        <Row gutter={16}>
          <Col span={16}>
            <Card style={{ width: "100%" }}>
              <LineItemsTable items={items} loading={loading} />
              <Divider />
              <OrderTotalSummary
                total={order.total}
                totalWithoutGst={order.totalWithoutGst}
                gstAmount={order.gstAmount}
                discountAmount={order.discountAmount}
              />
            </Card>
          </Col>
          <Col span={8}>
            <OrderInfoCard customer={customer} order={order} />
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};
