import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Row,
  Col,
  Card,
  PageHeader,
  Table,
  Statistic,
  Tag,
  Space,
  Tabs,
  Typography,
  Spin,
  Badge,
  Divider,
  Tooltip,
} from "antd";
import { MoneyCell } from "../components/tablecells";
import { useParams } from "react-router-dom";
import { PageContainer } from "../components/atoms/PageContainer";
import {
  EditFilled,
  FilePdfOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
  LockFilled,
} from "@ant-design/icons";
import {
  CustomerAccountBalanceHealthQuery,
  SingleCustomerQuery,
} from "../graphql/customers.graphql";
import { CustomerStatementsQuery } from "../graphql/customers.graphql";
import { CustomerDetailCard } from "../components/CustomerDetailCard";
import { StatusTag } from "../components/tags/StatusTag";
import { formatMoney } from "../utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CustomerStatementPDF } from "../components/pdf/CustomerStatementPDF";
import { CustomerStatusTag } from "@/components/CustomerStatusTag";

const { TabPane } = Tabs;

const invoicesColumns = [
  {
    title: "#",
    dataIndex: "name",
    key: "name",
    render: (name, record) => (
      <Link to={record.isCreditNote ? `/credit-notes/${record.id}` : `/orders/${record.id}`}>
        <Space>
          {name}
          <StatusTag status={record.status} />
        </Space>
      </Link>
    ),
  },
  {
    title: "Status",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (paymentStatus) => <StatusTag status={paymentStatus} />,
  },
  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
    render: (_, record) => {
      if (record.payments.length > 0) {
        return (
          <Space>
            {record.payments
              .filter((payment) => payment.name === "Discount")
              .map((payment) => {
                return <Tag>${payment.amount}</Tag>;
              })}
          </Space>
        );
      }
    },
  },
  {
    title: "Payments",
    dataIndex: "payment",
    key: "payment",
    render: (_, record) => {
      if (record.payments.length > 0) {
        return (
          <Space>
            {record.payments
              .filter((payment) => payment.name === "Payment")
              .map((payment) => {
                return (
                  <Link key={payment.batch?.id} to={`/payments/${payment.batch?.id}`}>
                    <Tag>${payment.amount}</Tag>
                  </Link>
                );
              })}
          </Space>
        );
      }
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    ...MoneyCell,
  },
];

const paymentsColumns = [
  {
    title: "Date",
    dataIndex: "receivedDate",
    key: "receivedDate",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },

  {
    title: "Invoices",
    dataIndex: "invoices",
    key: "invoices",
    render: (_, record) => {
      if (record.payments.length > 0) {
        const mapped = record.payments.map((payment) => payment.order);
        const mappedSet = new Set(mapped);
        const mappedArray = Array.from(mappedSet);
        return (
          <Space>
            {mappedArray.map((order) => {
              return (
                <Link key={order.id} to={`/orders/${order.id}`}>
                  <Tag># {order.name}</Tag>
                </Link>
              );
            })}
          </Space>
        );
      }
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    ...MoneyCell,
  },
];

export const CustomerDetail = () => {
  const params = useParams();
  const [customer, setCustomer] = useState({ name: "" });
  const [statementData, setStatementData] = useState({ name: "" });
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalSales, setTotalSales] = useState(null);
  const [totalPayments, setTotalPayments] = useState(null);
  const [totalExpectedBalance, setTotalExpectedBalance] = useState(0);

  const [healthy, setHealthy] = useState(null);
  const navigate = useNavigate();

  const { data, loading } = useQuery(SingleCustomerQuery, {
    variables: { id: params.id, take: 10, skip: (currentPage - 1) * 10 },
    fetchPolicy: "cache-and-network",
  });

  const { data: customerStatementsData, loading: customerStatementsLoading } = useQuery(
    CustomerStatementsQuery,
    {
      variables: { id: params.id },
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: customerAccountBalanceHealthData } = useQuery(CustomerAccountBalanceHealthQuery, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (customerStatementsData?.customer) {
      // console.log("customerStatementsData: ", customerStatementsData);
      setStatementData(customerStatementsData?.customer);
    }
  }, [customerStatementsData]);

  useEffect(() => {
    if (data?.customer) {
      setCustomer(data.customer);
      setInvoices(data.customer.orders);
      setPayments(data.customer.paymentBatches);
    }
  }, [data]);

  useEffect(() => {
    if (customerAccountBalanceHealthData?.customer) {
      const totalConfirmedSales = customerAccountBalanceHealthData.customer.orders.reduce(
        (prev, current) => prev + parseFloat(current.total),
        0
      );
      const totalReceivedPayments = customerAccountBalanceHealthData.customer.paymentBatches.reduce(
        (prev, current) => prev + parseFloat(current.amount),
        0
      );
      const expected = totalConfirmedSales - totalReceivedPayments;
      setTotalExpectedBalance(expected);
      setTotalSales(totalConfirmedSales);
      setTotalPayments(totalReceivedPayments);
      expected.toFixed(2) !== customer?.accountBalance ? setHealthy(false) : setHealthy(true);
    }
  }, [customer?.accountBalance, customerAccountBalanceHealthData]);

  return (
    <PageContainer>
      <PageHeader
        onBack={() => navigate(-1)}
        title={customer.name}
        subTitle={
          <Space>
            <span>#{customer.customerCode}</span>
            <CustomerStatusTag status={customer.status} />
          </Space>
        }
        extra={[
          <Link key="new-order" to={`/orders/new?customerId=${customer.id}`}>
            <Button type="primary" disabled loading={loading} icon={<EditFilled />}>
              New order
            </Button>
          </Link>,
        ]}
      />

      <Row gutter={32}>
        <Col span={8}>
          <Card
            title="Account balance"
            extra={[
              <PDFDownloadLink
                key="report"
                document={<CustomerStatementPDF data={statementData} />}
                fileName={`${customer.name}_${customer.customerCode}_as_of_${moment().format(
                  "DD/MM/YYYY"
                )}.pdf`}
              >
                <Button
                  type="dashed"
                  icon={<FilePdfOutlined />}
                  loading={customerStatementsLoading}
                >
                  Statement
                </Button>
              </PDFDownloadLink>,
            ]}
          >
            <Statistic value={`$ ${customer.accountBalance || "0.00"}`} />
            <Divider />
            {healthy === null ? (
              <Spin />
            ) : (
              <Space direction="vertical">
                <Space>
                  <Typography.Text>Status:</Typography.Text>
                  <CustomerStatusTag status={customer.status} />
                </Space>
                <Space>
                  <Badge status={healthy ? "success" : "error"} text="Health:" />
                  <Typography.Text type={healthy ? "success" : "danger"}>
                    {healthy ? "Good" : "Please check the balance"}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text>Total confirmed sales:</Typography.Text>
                  <Typography.Text strong>
                    {formatMoney(totalSales)}{" "}
                    <Tooltip title="Only order with status CONFIRMED is calculated">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text>Total received payments:</Typography.Text>
                  <Typography.Text strong>{formatMoney(totalPayments)}</Typography.Text>
                </Space>
                <Space>
                  <Typography.Text>Total expected balance:</Typography.Text>
                  <Typography.Text strong>{formatMoney(totalExpectedBalance)}</Typography.Text>
                </Space>
              </Space>
            )}
          </Card>
          <CustomerDetailCard customer={customer} title="Basic info" />
        </Col>
        <Col span={16}>
          <Card title="History">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Invoices" key="1">
                <Table
                  dataSource={invoices}
                  columns={invoicesColumns}
                  rowKey="id"
                  size="medium"
                  loading={loading}
                  pagination={{
                    total: data?.customer?.ordersCount,
                    pageSize: 10,
                    showSizeChanger: false,
                    onChange: (currentPage) => {
                      setCurrentPage(currentPage);
                    },
                  }}
                />
              </TabPane>
              <TabPane tab="Payments" key="2">
                <Table
                  dataSource={payments}
                  columns={paymentsColumns}
                  rowKey="id"
                  size="medium"
                  loading={loading}
                  pagination={false}
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};
