import { createContext } from "react";

const OrderFormContext = createContext({ customer: null, fetchCustomer: () => null, form: null });

const OrderFormProvider = ({ isEditing, order, historyData, form, activeQuotation, children }) => {
  return (
    <OrderFormContext.Provider value={{ form, historyData, order, isEditing, activeQuotation }}>
      {children}
    </OrderFormContext.Provider>
  );
};

export { OrderFormProvider, OrderFormContext };
