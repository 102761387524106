export const prepareCustomerFormValues = (values, operation = "edit", user) => {
  switch (values.actionType) {
    case "connect":
      values.franchise = { connect: { id: values.franchiseId } };
      break;
    case "disconnect":
      if (operation === "edit") {
        values.franchise = { disconnect: true };
      } else {
        delete values.franchise;
      }
      break;
    case "create":
      if (values.franchiseName) {
        values.franchise = { create: { name: values.franchiseName, isFranchise: true } };
      }
      break;
    default:
      break;
  }

  delete values.franchiseName;
  delete values.franchiseId;
  delete values.actionType;

  switch (operation) {
    case "edit":
      if (!values.salesperson) {
        values.salesperson = { disconnect: true };
      } else {
        values.salesperson = { connect: { id: values.salesperson } };
      }
      break;
    case "new":
      if (!values.salesperson) {
        delete values.salesperson;
      } else {
        values.salesperson = { connect: { id: values.salesperson } };
      }
      values.organisation = { connect: { id: user.organisations[0].id } };
      values.invoiceTemplate = "EVER_FRESH";
      break;
    default:
      break;
  }

  return values;
};
