import { gql } from "@apollo/client";

export const CreatePaymentBatchMutation = gql`
  mutation CreatePaymentBatchMutation($data: PaymentBatchCreateInput!) {
    createPaymentBatch(data: $data) {
      id
    }
  }
`;

export const UpdatePaymentBatchMutaion = gql`
  mutation UpdatePaymentBatchMutaion($id: ID!, $data: PaymentBatchUpdateInput!) {
    updatePaymentBatch(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export const SinglePaymentBatchQuery = gql`
  query SinglePaymentBatchQuery($id: ID!) {
    paymentBatch(where: { id: $id }) {
      id
      name
      receivedDate
      amount
      paymentMethod
      notes
      customer {
        id
        name
      }
      payments(orderBy: { createdAt: asc }) {
        id
        name
        notes
        amount
        order {
          id
          name
          paymentStatus
          balancePaid
          total
          balanceDue
        }
      }
    }
  }
`;

export const DeletePaymentBatchMutation = gql`
  mutation DeletePaymentBatchMutation($id: ID!) {
    deletePaymentBatch(where: { id: $id }) {
      id
    }
  }
`;

export const PaymentBatchesQuery = gql`
  query PaymentBatchesQuery($take: Int, $skip: Int, $where: PaymentBatchWhereInput) {
    paymentBatches(take: $take, skip: $skip, where: $where, orderBy: { createdAt: desc }) {
      id
      name
      sequence
      paymentType
      receivedDate
      amount
      notes
      customer {
        id
        name
      }
      payments {
        id
        order {
          id
          name
        }
      }
    }
    paymentBatchesCount(where: $where)
  }
`;
