import { Space, Avatar, Typography, Card } from "antd";
import { CustomerStatusTag } from "../CustomerStatusTag";
export const CustomerDetailCard = ({
  customer = { name: "" },
  title = "Selected customer",
  footer,
  extra,
}) => {
  const { name, email, phone, billingAddress, businessName, deliveryAddress, status } = customer;
  return (
    <Card title={title} actions={[footer]} extra={extra}>
      <Space direction="vertical">
        <Space>
          <Avatar>{customer.name?.charAt(0).toUpperCase()}</Avatar>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {name}
          </Typography.Title>
        </Space>
        <CustomerStatusTag status={status} />
        <Typography.Text>Business name: {businessName}</Typography.Text>
        <Typography.Text>Email: {email}</Typography.Text>
        <Typography.Text>Phone: {phone}</Typography.Text>
        <Typography.Text>Delivery Address: {deliveryAddress}</Typography.Text>
        <Typography.Text>Billing Address: {billingAddress}</Typography.Text>
      </Space>
    </Card>
  );
};
