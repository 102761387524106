import { Form, Col, Row, Card, Input, Select, Typography } from "antd";

const PAYMENT_TERMS = [
  { label: "Weekly", value: "weekly" },
  { label: "Fortnightly", value: "fortnightly" },
  { label: "Monthly", value: "monthly" },
  { label: "COD", value: "cod" },
];

const { Title } = Typography;

export const CustomerForm = ({ form }) => {
  return (
    <Form form={form} layout="vertical" style={{ padding: "16px 24px" }}>
      <Row gutter={[32, 16]}>
        <Col span={8}>
          <Title level={4}>Basics</Title>
          <Typography.Paragraph>
            Name, address, phone, email, and other basic information.
          </Typography.Paragraph>
        </Col>
        <Col span={16}>
          <Card>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "please input a customer name" }]}
            >
              <Input placeholder="Customer's name/store name/restaurant name" />
            </Form.Item>
            <Form.Item name="businessName" label="Business name">
              <Input placeholder="Customer's regsitered business name" />
            </Form.Item>
            <Form.Item name="abn" label="ABN">
              <Input placeholder="ABN" />
            </Form.Item>
            <Form.Item name="deliveryAddress" label="Delivery Address">
              <Input placeholder="Delivery address" />
            </Form.Item>
            <Form.Item name="billingAddress" label="Billing Address">
              <Input placeholder="Billing address" />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Title level={4}>Contact details</Title>
          <Typography.Paragraph>Add contact details.</Typography.Paragraph>
        </Col>
        <Col span={16}>
          <Card>
            <Form.Item name="firstName" label="First name">
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item name="lastName" label="Last name">
              <Input placeholder="Last name" />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input placeholder="Email address" />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
              <Input placeholder="Phone number" />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Title level={4}>Notes</Title>
          <Typography.Paragraph>Add notes to the customer.</Typography.Paragraph>
        </Col>
        <Col span={16}>
          <Card>
            <Form.Item
              name="deliveryNotes"
              label="Delivery notes"
              extra="This note will be displayed on Invoice and Delivery Docket. It can be edited on each
              order."
            >
              <Input.TextArea rows={4} placeholder="Add default delivery notes" />
            </Form.Item>
            <Form.Item
              name="notes"
              label="Customer notes"
              extra="This note is not shown to the customer."
            >
              <Input.TextArea rows={4} placeholder="Add general notes to the customer." />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Title level={4}>Payment</Title>
          <Typography.Paragraph>Select payment options for the customer.</Typography.Paragraph>
        </Col>
        <Col span={16}>
          <Card>
            <Form.Item name="paymentTerms" label="Payment terms">
              <Select options={PAYMENT_TERMS} defaultValue="weekly" />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
